import React, { useState, useEffect } from 'react';

const StudentEdit = ({ studentId }) => {
  const [student, setStudent] = useState(null);
  const [formData, setFormData] = useState({
    nama: '',
    kursus: '',
    intake: '',
    status: '',
    // Add more fields as needed
  });

  useEffect(() => {
    fetch(`https://studentspopulation.online/api/students.php?id=${studentId}`)
      .then(response => response.json())
      .then(data => {
        setStudent(data);
        setFormData({
          nama: data.nama,
          kursus: data.kursus,
          intake: data.intake,
          status: data.status,
          // Initialize more fields as needed
        });
      })
      .catch(error => console.error('Error fetching student:', error));
  }, [studentId]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`https://studentspopulation.online/server/students.php?id=${studentId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(data => {
        alert('Student updated successfully');
        // Optionally update state or notify parent component
      })
      .catch(error => console.error('Error updating student:', error));
  };

  if (!student) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <h2 className="mt-4">Edit Student</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            name="nama"
            value={formData.nama}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Course</label>
          <input
            type="text"
            className="form-control"
            name="kursus"
            value={formData.kursus}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Intake</label>
          <input
            type="text"
            className="form-control"
            name="intake"
            value={formData.intake}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Status</label>
          <input
            type="text"
            className="form-control"
            name="status"
            value={formData.status}
            onChange={handleInputChange}
            required
          />
        </div>
        {/* Add more fields as needed */}
        <button type="submit" className="btn btn-primary">Update</button>
      </form>
    </div>
  );
};

export default StudentEdit;
