import React, { useState, useEffect } from 'react';

const StudentsData = ({ user }) => {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    fetch('https://studentspopulation.online/server/students.php')
      .then(response => response.json())
      .then(data => setStudents(data))
      .catch(error => console.error('Error fetching students:', error));
  }, []);

  const canEdit = user.role === 'admin';

  const handleView = (studentId) => {
    // Implement view logic here, such as navigating to a student details page
    console.log(`View student with ID: ${studentId}`);
  };

  const handleEdit = (studentId) => {
    // Implement edit logic here, such as navigating to an edit student page
    console.log(`Edit student with ID: ${studentId}`);
  };

  return (
    <div className="container">
      <h2 className="mt-4">Students Data</h2>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Course</th>
            <th>Intake</th>
            <th>Status</th>
            {canEdit && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {students.map(student => (
            <tr key={student.id}>
              <td>{student.id}</td>
              <td>{student.nama}</td>
              <td>{student.kursus}</td>
              <td>{student.intake}</td>
              <td>{student.status}</td>
              {canEdit && (
                <td>
                  <button
                    className="btn btn-sm btn-primary me-2"
                    onClick={() => handleView(student.id)}
                  >
                    View
                  </button>
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => handleEdit(student.id)}
                  >
                    Edit
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentsData;
