import React, { useState, useEffect } from 'react';

const UserProfile = ({ user, setUser }) => {
  const [name, setName] = useState(user.name);
  const [password, setPassword] = useState('');

  useEffect(() => {
    setName(user.name);
  }, [user]);

  const handleUpdate = (e) => {
    e.preventDefault();

    fetch(`https://studentspopulation.online/server/users.php`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: user.id,
        name,
        password,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status_message === 'User updated successfully') {
          alert('Profile updated successfully');
          setUser({ ...user, name });
          localStorage.setItem('user', JSON.stringify({ ...user, name }));
        } else {
          alert(data.status_message);
        }
      })
      .catch(error => console.error('Error updating profile:', error));
  };

  return (
    <div className="container">
      <h2 className="mt-4">User Profile</h2>
      <form onSubmit={handleUpdate}>
        <div className="mb-3">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Password</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Leave blank to keep current password"
          />
        </div>
        <button type="submit" className="btn btn-primary">Update Profile</button>
      </form>
    </div>
  );
};

export default UserProfile;
