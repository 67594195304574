import React, { useState, useEffect } from 'react';

const StudentView = ({ studentId }) => {
  const [student, setStudent] = useState(null);

  useEffect(() => {
    fetch(`https://studentspopulation.online/server/students.php?id=${studentId}`)
      .then(response => response.json())
      .then(data => setStudent(data))
      .catch(error => console.error('Error fetching student:', error));
  }, [studentId]);

  if (!student) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <h2 className="mt-4">Student Details</h2>
      <div>
        <strong>ID:</strong> {student.id}<br />
        <strong>Name:</strong> {student.nama}<br />
        <strong>Course:</strong> {student.kursus}<br />
        <strong>Intake:</strong> {student.intake}<br />
        <strong>Status:</strong> {student.status}<br />
        {/* Add more fields as needed */}
      </div>
    </div>
  );
};

export default StudentView;
