import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import UserProfile from './components/UserProfile';
import StudentsData from './components/StudentsData';
import StudentView from './components/StudentView';
import StudentEdit from './components/StudentEdit';
import LoginPage from './components/LoginPage';

const App = () => {
  const [user, setUser] = useState(null); // Assuming user state management

  return (
    <Router>
      <div>
        <Navbar user={user} setUser={setUser} />

        <div className="container mt-4">
          <Routes>
            <Route path="/" element={user ? <Navigate to="/profile" /> : <LoginPage setUser={setUser} />} />
            <Route path="/profile" element={user ? <UserProfile user={user} setUser={setUser} /> : <Navigate to="/" />} />
            <Route path="/students" element={user ? <StudentsData user={user} /> : <Navigate to="/" />} />
            <Route path="/students/view/:id" element={user ? <StudentView /> : <Navigate to="/" />} />
            <Route path="/students/edit/:id" element={user ? <StudentEdit /> : <Navigate to="/" />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
